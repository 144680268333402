import { createContext, useEffect } from 'react';

const ThemeContext = createContext(null);

const ThemeProvider = ({ children }) => {
  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('dark');
    }

    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        if (e.matches) {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
      });
  }, []);

  return <ThemeContext.Provider value={null}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;