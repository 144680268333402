import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView, trackSEOMetrics } from '../utils/analytics';

export const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Verifică dacă există consimțământul pentru cookie-uri
    const checkConsentAndTrack = () => {
      const consentData = localStorage.getItem('cookieConsent');
      if (consentData) {
        try {
          const { status, expires } = JSON.parse(consentData);
          if (new Date(expires) > new Date() && status === 'accepted') {
            // Track normal page view
            trackPageView(location.pathname + location.search);
            
            // Track SEO metrics
            trackSEOMetrics({
              source: document.referrer,
              user_agent: navigator.userAgent,
              screen_resolution: `${window.screen.width}x${window.screen.height}`,
              visit_time: new Date().toISOString()
            });
          }
        } catch (e) {
          console.error('Error parsing consent data:', e);
        }
      }
    };

    checkConsentAndTrack();
  }, [location]);

  return null;
};