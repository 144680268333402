import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo/logo_with_background_rounded.webp';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Helper function to determine if a link is active
  const isActivePath = (path) => {
    return location.pathname === path;
  };

  const navLinks = [
    { title: 'Home', path: '/' },
    { title: 'Features', path: '/features' },
    // { title: 'Community', path: '/community' },
    { title: 'Pricing', path: '/pricing' }
  ];

  const handleSignUp = () => {
    navigate('/waitlist');
    setIsMenuOpen(false); // Close mobile menu if open
  };

  return (
    <nav className="fixed w-full z-50 bg-white dark:bg-black/80 shadow-md dark:shadow-none 
      dark:border-b dark:border-white/5 py-4 dark:backdrop-blur-lg transition-all duration-300">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center space-x-2">
            <img
              src={logo}
              alt="FITVERSE Logo"
              className="w-8 h-8 rounded-lg dark:brightness-110"
            />
            <span className="font-bold text-xl text-gray-900 dark:text-white">FITVERSE</span>
          </Link>

          <div className="hidden md:flex items-center space-x-4">
            {navLinks.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className={`px-4 py-2 rounded-lg transition-all duration-300 ${
                  isActivePath(link.path)
                    ? 'bg-gradient-to-r from-red-500 to-red-500 dark:from-red-600 dark:to-red-500 text-white shadow-lg shadow-red-500/20'
                    : 'hover:bg-red-100 dark:text-gray-300 dark:hover:bg-white/5 dark:hover:text-white'
                }`}
              >
                {link.title}
              </Link>
            ))}
            <button
              onClick={handleSignUp}
              className="ml-4 px-6 py-2 bg-gradient-to-r from-red-500 to-red-500 
                dark:from-red-600 dark:to-red-500 text-white rounded-lg transition-all 
                duration-300 hover:shadow-lg hover:shadow-red-500/20 dark:shadow-red-500/20 
                hover:-translate-y-0.5"
            >
              Sign Up
            </button>
          </div>

          <button
            className="md:hidden p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-white/5 
              text-gray-900 dark:text-white transition-colors"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        {isMenuOpen && (
          <div className="md:hidden absolute top-full left-0 right-0 bg-white dark:bg-black/90 
            shadow-lg dark:shadow-none dark:border-b dark:border-white/5 py-4 px-4 space-y-2
            dark:backdrop-blur-lg">
            {navLinks.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className={`block px-4 py-2 rounded-lg transition-all duration-300 ${
                  isActivePath(link.path)
                    ? 'bg-gradient-to-r from-red-500 to-red-500 dark:from-red-600 dark:to-red-500 text-white'
                    : 'hover:bg-red-100 dark:text-gray-300 dark:hover:bg-white/5 dark:hover:text-white'
                }`}
                onClick={() => setIsMenuOpen(false)}
              >
                {link.title}
              </Link>
            ))}
            <button
              onClick={handleSignUp}
              className="w-full px-6 py-2 mt-4 bg-gradient-to-r from-red-500 to-red-500 
                dark:from-red-600 dark:to-red-500 text-white rounded-lg transition-all 
                duration-300 hover:shadow-lg hover:shadow-red-500/20 dark:shadow-red-500/20"
            >
              Sign Up
            </button>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;