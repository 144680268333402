import React, { useState } from 'react';
import { ChevronRight, Sparkles, CircuitBoard, LineChart, Target } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { IPhoneFrame, ImageDisplay } from '../common/PhoneDisplay';

// Import image using import statement to ensure proper bundling
import phoneImage from '../../assets/images/WorkoutDetails.webp';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="flex items-start space-x-3 group p-4 rounded-xl hover:bg-white/50 dark:hover:bg-white/5 
    transition-all duration-300 dark:border dark:border-white/5 dark:hover:border-white/10">
    <div className="text-[#ef4444] dark:text-red-400">
      <Icon size={32} strokeWidth={1.5} />
    </div>
    <div>
      <h3 className="font-semibold text-gray-900 dark:text-gray-100">{title}</h3>
      <p className="text-sm text-gray-500 dark:text-gray-400">{description}</p>
    </div>
  </div>
);

const features = [
  {
    icon: CircuitBoard,
    title: "Advanced AI Insights",
    description: "Leverage AI to understand your fitness progress deeply and make data-driven improvements."
  },
  {
    icon: LineChart,
    title: "Dynamic Progress Tracking",
    description: "Track every step of your journey and see your growth over time with detailed metrics."
  },
  {
    icon: Target,
    title: "Personalized Goal Setting",
    description: "Set achievable fitness goals and let our AI guide you towards surpassing them."
  }
];

const HeroSection = () => {
  const navigate = useNavigate();
  
  const { ref: sectionRef, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: imageRef, inView: imageInView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '50px 0px',
  });

  return (
    <section ref={sectionRef} className="pt-32 pb-20 px-4 overflow-hidden bg-white dark:bg-black">
      <div className="max-w-7xl mx-auto">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="space-y-8">
            <div className={`
              inline-flex items-center gap-2 bg-[#ef4444]/10 dark:bg-red-500/20 text-[#ef4444] dark:text-red-400 
              px-4 py-2 rounded-full text-sm font-medium transition-all duration-500
              dark:backdrop-blur-sm dark:border dark:border-red-500/20
              ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}
            `}>
              <Sparkles size={16} />
              <span>AI-Powered Fitness Transformation</span>
            </div>

            <h1 className={`
              text-5xl md:text-6xl font-bold leading-tight transition-all duration-500 delay-100
              text-gray-900 dark:text-white
              ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}
            `}>
              Track, Analyze, <span className="text-[#ef4444] dark:text-transparent dark:bg-clip-text 
                dark:bg-gradient-to-r dark:from-red-500 dark:to-red-600">Excel</span>
            </h1>

            <p className={`
              text-xl text-gray-600 dark:text-gray-400 transition-all duration-500 delay-200
              ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}
            `}>
              Join FitVerse to access personalized fitness insights and adaptive workouts tailored to your goals. 
              Discover a smarter way to achieve your best self with AI-driven feedback and detailed progress tracking.
            </p>

            <div className={`
              flex flex-wrap gap-4 transition-all duration-500 delay-300
              ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}
            `}>
              <button
                onClick={() => navigate('/waitlist')}
                className="
                  bg-gradient-to-r from-[#ef4444] to-[#ef4444]/80 dark:from-red-600 dark:to-red-500 
                  text-white px-8 py-3.5 rounded-xl font-semibold flex items-center group 
                  relative overflow-hidden shadow-lg shadow-red-500/30 dark:shadow-red-500/20 
                  hover:shadow-red-500/50 transform hover:-translate-y-0.5 transition-all duration-300
                  dark:backdrop-blur-sm
                "
              >
                <span className="relative z-10">Start Your Journey</span>
                <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform relative z-10" />
                <div className="
                  absolute inset-0 bg-gradient-to-r from-[#ef4444]/90 to-[#ef4444] 
                  dark:from-red-600 dark:to-red-500 opacity-0 group-hover:opacity-100 
                  transition-opacity duration-300
                "/>
              </button>

              <button
                onClick={() => navigate('/features')}
                className="
                  bg-white dark:bg-black/50 border-2 border-[#ef4444]/20 dark:border-red-500/20 
                  text-[#ef4444] dark:text-red-400 px-8 py-3.5 rounded-xl font-semibold 
                  flex items-center group hover:border-[#ef4444]/40 dark:hover:border-red-500/30 
                  transform hover:-translate-y-0.5 transition-all duration-300 
                  hover:shadow-lg hover:shadow-red-500/20 dark:backdrop-blur-sm
                "
              >
                <span>See How It Works</span>
                <ChevronRight className="
                  ml-2 opacity-0 group-hover:opacity-100 -translate-x-2 
                  group-hover:translate-x-0 transition-all duration-300
                "/>
              </button>
            </div>

            <div className={`
              grid grid-cols-1 sm:grid-cols-3 gap-6 pt-4 transition-all duration-500 delay-400
              ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}
            `}>
              {features.map((feature, index) => (
                <FeatureCard key={index} {...feature} />
              ))}
            </div>
          </div>

          <div className="relative">
            <div className={`
              relative z-10 transition-all duration-500 delay-500
              ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}
            `}>
              <IPhoneFrame hasDynamicIsland={true}>
                <ImageDisplay
                  src={phoneImage}
                  alt="FitVerse Workout Details"
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </IPhoneFrame>
            </div>
            
            <div className={`
              absolute -inset-10 bg-gradient-to-r from-[#ef4444]/10 dark:from-red-500/10 
              to-transparent blur-3xl -z-10 transition-opacity duration-500 delay-700
              ${inView ? 'opacity-100' : 'opacity-0'}
            `} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;