import React, { useState } from 'react';
import { Check, X } from 'lucide-react';

const PricingCard = ({ plan, isYearly }) => {
  const PricingFeature = ({ included, text }) => (
    <div className="flex items-center space-x-2">
      {included ? (
        <Check className="w-5 h-5 text-green-500 dark:text-green-400" />
      ) : (
        <X className="w-5 h-5 text-red-500 dark:text-red-400" />
      )}
      <span className={included ? 
        "text-gray-900 dark:text-gray-100" : 
        "text-gray-500 dark:text-gray-400"
      }>{text}</span>
    </div>
  );

  const renderPrice = () => (
    <div className="text-center mb-4">
      <div className="text-4xl font-bold text-gray-900 dark:text-gray-200 line-through">
        ${plan.price.toFixed(2)}
      </div>
      <div className="text-2xl font-bold text-green-500 dark:text-green-400">
        $0 for Waitlist Members
      </div>
    </div>
  );

  return (
    <div className={`p-8 rounded-xl bg-white dark:bg-black/50 shadow-lg 
      hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 
      backdrop-blur-sm border-2 border-transparent
      ${plan.popular ? 'border-red-500 dark:border-red-800' : ''}
      dark:shadow-red-900/10 dark:hover:shadow-red-900/20`}
    >
      <h3 className="text-2xl font-bold mb-2 text-gray-900 dark:text-white">{plan.name}</h3>
      {renderPrice()}
      <div className="space-y-3 mb-8">
        {plan.features.map((feature, index) => (
          <PricingFeature key={index} included={feature.included} text={feature.text} />
        ))}
      </div>
      <button
        className="w-full py-3 rounded-lg font-semibold bg-red-500 
          dark:bg-gradient-to-r dark:from-red-600 dark:to-red-500
          text-white hover:bg-red-600 transition-all duration-300
          dark:border dark:border-red-800/20 dark:hover:border-red-700/30
          hover:-translate-y-0.5 dark:hover:shadow-lg dark:hover:shadow-red-900/20
          backdrop-blur-sm"
        onClick={() => window.location.href = "/waitlist"}
      >
        Join the Waitlist Now
      </button>
    </div>
  );
};

const PricingSection = () => {
  const [isYearly, setIsYearly] = useState(false);

  const plans = [
    {
      name: "Early Access",
      price: 9.99,
      buttonText: "Join the Waitlist Now",
      popular: true,
      features: [
        { included: true, text: "Unlimited workouts" },
        { included: true, text: "Advanced exercise tracking" },
        { included: true, text: "Enhanced analytics dashboard" },
        { included: true, text: "AI workout recommendations" },
        { included: true, text: "Personalized training plans" },
        { included: true, text: "Priority support" },
        { included: true, text: "Export data & insights" },
      ],
    }
  ];

   return (
    <div className="min-h-screen bg-gray-50 dark:bg-black">
      {/* Header Section */}
      <section className="pt-32 pb-20 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="text-center space-y-8">
            <h2 className="text-4xl font-bold text-gray-900 dark:text-white">
              Don't Miss Out on Exclusive Benefits!
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
              Join now to enjoy premium features before anyone else.
            </p>

            {/* Alert Banner */}
            <div className="bg-yellow-100/80 dark:bg-yellow-900/20 text-yellow-800 
              dark:text-yellow-200 p-4 rounded-xl backdrop-blur-sm border 
              border-yellow-200 dark:border-yellow-800/20 max-w-3xl mx-auto">
              <p>Get premium access and personalized fitness features when you join our waitlist!</p>
            </div>
          </div>

          {/* Pricing Cards Container */}
          <div className="mt-16 flex justify-center items-center">
            {plans.map((plan, index) => (
              <PricingCard key={index} plan={plan} isYearly={isYearly} />
            ))}
          </div>
        </div>

        {/* Background Gradient */}
        <div className="fixed inset-0 bg-gradient-to-b from-red-900/5 via-transparent 
          to-transparent opacity-0 dark:opacity-100 pointer-events-none" />
      </section>
    </div>
  );
};

export default PricingSection;
