import { ANALYTICS_CONFIG } from '../config/analytics.config';

// Initialize GA
export const initGA = () => {
  if (typeof window.gtag !== 'function') return;

  window.gtag('js', new Date());
  window.gtag('config', ANALYTICS_CONFIG.GA_MEASUREMENT_ID, {
    send_page_view: false // We'll handle this manually
  });
};

// Track page view
export const trackPageView = (path, title) => {
  if (typeof window.gtag !== 'function') return;

  window.gtag('event', 'page_view', {
    page_path: path,
    page_title: title || document.title,
    ...ANALYTICS_CONFIG.DEFAULT_EVENT_PARAMS
  });
};

// Track event
export const trackEvent = (eventName, params = {}) => {
  if (typeof window.gtag !== 'function') return;

  window.gtag('event', eventName, {
    ...ANALYTICS_CONFIG.DEFAULT_EVENT_PARAMS,
    ...params
  });
};

// Track form submission
export const trackFormSubmission = (formName, success = true, additionalData = {}) => {
  trackEvent('form_submission', {
    form_name: formName,
    success,
    ...additionalData
  });
};

// Track error
export const trackError = (error, componentName) => {
  trackEvent('error', {
    error_message: error.message,
    error_type: error.name,
    component: componentName
  });
};

// Update consent
export const updateConsent = (consented) => {
  if (typeof window.gtag !== 'function') return;

  window.gtag('consent', 'update', {
    analytics_storage: consented ? 'granted' : 'denied'
  });

  trackEvent(ANALYTICS_CONFIG.EVENTS.COOKIE_CONSENT, {
    consent_granted: consented
  });
};

// Track SEO metrics
export const trackSEOMetrics = () => {
  if (typeof window.gtag !== 'function') return;

  window.gtag('event', 'page_view', {
    page_path: window.location.pathname,
    page_title: document.title
  });
};