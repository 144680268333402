import React from 'react';
import ContactHero from '../../components/contact/ContactHero';
import ContactInfo from '../../components/contact/ContactInfo';
import ContactForm from '../../components/contact/ContactForm';
import FAQSection from '../../components/contact/FAQSection';
import { usePageTime } from '../../hooks/usePageTime';

const ContactPage = () => {
  usePageTime();

  return (
    <div className="bg-gray-50 dark:bg-black pt-16"> {/* Added pt-16 here */}
      <ContactHero />
      <ContactInfo />
      <ContactForm />
      <FAQSection />
    </div>
  );
};

export default ContactPage;