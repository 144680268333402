import React, { useState } from 'react';
import { Send, Loader2, XCircle } from 'lucide-react';
import config from '../../config/config';
import { apiClient } from '../../utils/api';
import ContactSuccess from './ContactSuccess'; // Ensure you import the success component
import { usePageTime } from '../../hooks/usePageTime';

const ContactForm = () => {
  usePageTime();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const [form, setForm] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (config.debug) {
      console.log('=== Contact Form Submission Started ===');
      console.log('Environment:', config.environment);
      console.log('Form Data:', form);
    }

    setIsSubmitting(true);
    setError('');

    try {
      const response = await apiClient.post('/api/contact', form);

      if (config.debug) {
        console.log('Contact submission successful:', response);
      }

      setIsSuccess(true); // Mark success
    } catch (err) {
      if (config.debug) {
        console.error('Contact submission failed:', err);
      }

      if (err.status === 401) {
        setError('Authentication error. Please contact support.');
      } else if (err.status === 400) {
        setError(err.message);
      } else if (err.message.includes('Unable to connect')) {
        setError('Unable to connect to our servers. Please try again later.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
      if (config.debug) {
        console.log('=== Contact Form Submission Completed ===');
      }
    }
  };

  if (isSuccess) {
    return <ContactSuccess />; // Render the success component
  }

  return (
    <div className="min-h-screen bg-white dark:bg-black flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md ">
        {/* Back Button */}
        <div>
          <h2 className="mt-6 text-3xl font-bold text-gray-900 dark:text-white">Get in Touch</h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Have questions? We'd love to hear from you. Send us a message and we'll respond as soon as possible.
          </p>
        </div>

        {/* Error Message */}
        {error && (
          <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 
            text-red-800 dark:text-red-200 rounded-lg p-4 flex items-start mt-4">
            <XCircle className="w-5 h-5 text-red-400 dark:text-red-300 mr-2 flex-shrink-0 mt-0.5" />
            <span className="text-sm">{error}</span>
          </div>
        )}

        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="space-y-4">
            {/* Name Input */}
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
                required
                className="mt-1 block w-full rounded-lg border border-gray-300 dark:border-gray-700 
                  px-3 py-2 shadow-sm bg-white dark:bg-black dark:text-white
                  focus:border-[#ef4444] focus:ring-[#ef4444] dark:focus:border-red-800 
                  dark:focus:ring-red-800 focus:ring-opacity-50 focus:outline-none
                  dark:placeholder-gray-500"
              />
            </div>

            {/* Email Input */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                Email address
              </label>
              <input
                type="email"
                id="email"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
                required
                className="mt-1 block w-full rounded-lg border border-gray-300 dark:border-gray-700 
                  px-3 py-2 shadow-sm bg-white dark:bg-black dark:text-white
                  focus:border-[#ef4444] focus:ring-[#ef4444] dark:focus:border-red-800 
                  dark:focus:ring-red-800 focus:ring-opacity-50 focus:outline-none
                  dark:placeholder-gray-500"
              />
            </div>

            {/* Subject Input */}
            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                value={form.subject}
                onChange={(e) => setForm({ ...form, subject: e.target.value })}
                required
                className="mt-1 block w-full rounded-lg border border-gray-300 dark:border-gray-700 
                  px-3 py-2 shadow-sm bg-white dark:bg-black dark:text-white
                  focus:border-[#ef4444] focus:ring-[#ef4444] dark:focus:border-red-800 
                  dark:focus:ring-red-800 focus:ring-opacity-50 focus:outline-none
                  dark:placeholder-gray-500"
              />
            </div>

            {/* Message Input */}
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                Message
              </label>
              <textarea
                id="message"
                rows={6}
                value={form.message}
                onChange={(e) => setForm({ ...form, message: e.target.value })}
                required
                style={{ resize: 'none' }}
                className="mt-1 block w-full rounded-lg border border-gray-300 dark:border-gray-700 
                  px-3 py-2 shadow-sm bg-white dark:bg-black dark:text-white
                  focus:border-[#ef4444] focus:ring-[#ef4444] dark:focus:border-red-800 
                  dark:focus:ring-red-800 focus:ring-opacity-50 focus:outline-none
                  dark:placeholder-gray-500 resize-none !important"
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full flex justify-center items-center py-3 px-4 border border-transparent 
              rounded-lg shadow-sm text-sm font-medium text-white 
              bg-[#ef4444] dark:bg-gradient-to-r dark:from-red-600 dark:to-red-500
              hover:bg-[#ef4444]/90 dark:hover:shadow-lg dark:hover:shadow-red-900/30
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ef4444] 
              dark:focus:ring-red-800 transition-all duration-200 
              disabled:opacity-50 dark:disabled:opacity-40
              hover:-translate-y-0.5 dark:border-red-800/20 dark:hover:border-red-700/30
              dark:backdrop-blur-sm"
          >
            {isSubmitting ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Sending...
              </>
            ) : (
              <>
                <Send className="w-4 h-4 mr-2" />
                Send Message
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
