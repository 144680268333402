import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { usePageTime } from '../hooks/usePageTime';
import { useScrollToTop } from '../hooks/useScrollToTop';

const CookiePolicy = () => {
  usePageTime();
  useScrollToTop();

  return (
    <div className="pt-24 bg-white dark:bg-black min-h-screen">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">
          Cookie Policy
        </h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
            What Are Cookies
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            Cookies are small text files that are placed on your device when you visit our website. 
            They help us provide you with a better experience and allow us to improve our services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
            How We Use Cookies
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            We use cookies for the following purposes:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-700 dark:text-gray-300">
            <li>
              <span className="font-semibold text-gray-900 dark:text-white">Essential Cookies:</span> Required for the website to function properly
            </li>
            <li>
              <span className="font-semibold text-gray-900 dark:text-white">Analytics Cookies:</span> Help us understand how visitors interact with our website (Google Analytics 4)
            </li>
            <li>
              <span className="font-semibold text-gray-900 dark:text-white">Preference Cookies:</span> Remember your cookie consent choices
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
            Cookie Types We Use
          </h2>
          <div className="space-y-4">
            <div className="border border-gray-200 dark:border-gray-800 rounded-lg p-4 
              bg-white dark:bg-gray-900">
              <h3 className="font-semibold mb-2 text-gray-900 dark:text-white">
                Google Analytics 4
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">Purpose: Website analytics</p>
              <p className="text-sm text-gray-600 dark:text-gray-400">Duration: 2 years</p>
              <p className="text-sm text-gray-600 dark:text-gray-400">Type: Third-party analytics</p>
            </div>
            
            <div className="border border-gray-200 dark:border-gray-800 rounded-lg p-4 
              bg-white dark:bg-gray-900">
              <h3 className="font-semibold mb-2 text-gray-900 dark:text-white">
                Cookie Consent
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">Purpose: Remember your cookie preferences</p>
              <p className="text-sm text-gray-600 dark:text-gray-400">Duration: 1 year</p>
              <p className="text-sm text-gray-600 dark:text-gray-400">Type: Essential</p>
            </div>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
            Managing Cookies
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            You can manage your cookie preferences at any time by clicking the "Cookie Settings" 
            button in our website footer. You can also control cookies through your browser settings.
          </p>
        </section>

        <div className="mt-12 pt-8 border-t border-gray-200 dark:border-gray-800">
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Last updated: November 9, 2024
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;