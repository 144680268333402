import config from '../config/config';

// Get API key from config
const API_KEY = config.apiKey;

// Validate API key availability early
const validateEnvironment = () => {
  if (!API_KEY) {
    console.error('API key is not set in environment variables. Please check your .env file.');
    console.error('Expected environment variable: REACT_APP_API_KEY');
    console.error('Current environment:', process.env.NODE_ENV);
    return false;
  }
  return true;
};

class ApiError extends Error {
  constructor(message, status, data) {
    super(message);
    this.name = 'ApiError';
    this.status = status;
    this.data = data;
  }
}

const logApiCall = (method, url, data = null) => {
  if (config.debug) {
    console.log(`=== API Call: ${method} ${url} ===`, {
      timestamp: new Date().toISOString(),
      method,
      url,
      data: data ? { ...data, sensitive: '[REDACTED]' } : null,
      hasApiKey: !!API_KEY,
      environment: process.env.NODE_ENV
    });
  }
};

export const apiClient = {
  baseUrl: config.apiUrl,
  isInitialized: validateEnvironment(),
  
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-API-Key': API_KEY
  },

  async post(endpoint, data) {
    if (!this.isInitialized) {
      throw new ApiError(
        'API client is not properly initialized. Check environment variables.',
        500,
        { missingKey: 'REACT_APP_API_KEY' }
      );
    }

    const url = `${this.baseUrl}${endpoint}`;
    logApiCall('POST', url, data);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: this.headers,
        mode: 'cors',
        credentials: 'omit',
        body: JSON.stringify(data)
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new ApiError(
          responseData.error || 'Request failed',
          response.status,
          responseData
        );
      }

      return responseData;
    } catch (error) {
      if (error instanceof ApiError) {
        throw error;
      }

      if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
        throw new ApiError(
          `Unable to connect to server at ${this.baseUrl}`,
          0,
          { originalError: error }
        );
      }

      throw new ApiError(error.message, 500, { originalError: error });
    }
  }
};