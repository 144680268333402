// src/pages/about/index.js
import React from 'react';
import HeroSection from '../../components/about/HeroSection';
import ValuesSection from '../../components/about/ValuesSection';
// import AchievementsSection from '../../components/about/AchievementsSection';
// import TeamSection from '../../components/about/TeamSection';
import CTASection from '../../components/about/CTASection';
import { Heart, Target, Users, Dumbbell } from 'lucide-react';
import { usePageTime } from '../../hooks/usePageTime';

const AboutPage = () => {
  usePageTime();

  const values = [
    {
      icon: Heart,
      title: "Passion for Fitness",
      description: "We're driven by our love for fitness and helping others achieve their health goals."
    },
    {
      icon: Target,
      title: "Goal-Oriented",
      description: "We focus on delivering measurable results and helping you reach your fitness targets."
    },
    {
      icon: Users,
      title: "Community-Driven",
      description: "We believe in the power of community support and motivation in fitness journeys."
    },
    {
      icon: Dumbbell,
      title: "Expert Knowledge",
      description: "Our team combines fitness expertise with cutting-edge technology."
    }
  ];

  // const achievements = [
  //   { value: "10K+", label: "Active Users" },
  //   { value: "500K+", label: "Workouts Completed" },
  //   { value: "95%", label: "Satisfaction Rate" },
  //   { value: "50+", label: "Expert Trainers" }
  // ];

  // const team = [
  //   {
  //     name: "John Smith",
  //     role: "Founder & CEO",
  //     description: "Fitness enthusiast with 10+ years of experience in digital health.",
  //     image: "/path/to/john.jpg"
  //   },
  //   {
  //     name: "Sarah Johnson",
  //     role: "Head of AI Development",
  //     description: "AI expert specializing in personalized fitness algorithms.",
  //     image: "/path/to/sarah.jpg"
  //   },
  //   {
  //     name: "Mike Williams",
  //     role: "Lead Fitness Trainer",
  //     description: "Certified trainer with expertise in strength and conditioning.",
  //     image: "/path/to/mike.jpg"
  //   }
  // ];

  return (
    <div className="bg-gray-50">
      <HeroSection />
      <ValuesSection values={values} />
      {/* <AchievementsSection achievements={achievements} /> */}
      {/* <TeamSection team={team} /> */}
      <CTASection />
    </div>
  );
};

export default AboutPage;