import React from 'react';
// import { Link } from 'react-router-dom';
import { usePageTime } from '../hooks/usePageTime';

const PrivacyPolicy = () => {
  usePageTime();

  return (
    <div className="min-h-screen bg-white dark:bg-black pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
          Privacy Policy
        </h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
            Analytics and Cookies
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300 leading-relaxed">
            We use Google Analytics 4 to understand how visitors interact with our website. 
            This service uses cookies to collect anonymous information such as:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-700 dark:text-gray-300 space-y-2">
            <li>Number of visitors to the site</li>
            <li>Pages visited</li>
            <li>Time spent on each page</li>
            <li>Approximate geographic location (country/city)</li>
            <li>Device and browser information</li>
          </ul>
          <p className="mb-4 text-gray-700 dark:text-gray-300 leading-relaxed">
            This data helps us improve our services and user experience. No personally 
            identifiable information is collected through Google Analytics.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
            Data Collection
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300 leading-relaxed">
            When you join our waitlist or contact us, we collect:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-700 dark:text-gray-300 space-y-2">
            <li>Name</li>
            <li>Email address</li>
            <li>Message content (for contact form)</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
            Data Usage
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300 leading-relaxed">
            We use your data to:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-700 dark:text-gray-300 space-y-2">
            <li>Send important updates about FitVerse</li>
            <li>Respond to your inquiries</li>
            <li>Improve our services</li>
            <li>Send promotional content (if you've opted in)</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
            Your Rights
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300 leading-relaxed">
            You have the right to:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-700 dark:text-gray-300 space-y-2">
            <li>Access your personal data</li>
            <li>Request data correction</li>
            <li>Request data deletion</li>
            <li>Opt-out of communications</li>
            <li>Withdraw cookie consent</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
            Contact Us
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300 leading-relaxed">
            For any privacy-related questions, please contact us at{' '}
            <a 
              href="mailto:info@fitversehub.com" 
              className="text-red-600 dark:text-red-400 hover:text-red-700 
                dark:hover:text-red-300 underline"
            >
              info@fitversehub.com
            </a>
          </p>
        </section>

        <div className="mt-12 pt-8 border-t border-gray-200 dark:border-gray-800">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Last updated: November 9, 2024
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;