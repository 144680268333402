import React from 'react';
import PricingSection from '../components/pricing/PricingSection';
import { usePageTime } from '../hooks/usePageTime';

const Pricing = () => {
  usePageTime();

  return (
    <main className="pt-20 bg-gray-50 dark:bg-black">
      <PricingSection />
    </main>
  );
};

export default Pricing;