import React from 'react';
import { useInView } from 'react-intersection-observer';

const ValueCard = ({ value }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  return (
    <div 
      ref={ref}
      className={`
        p-6 rounded-2xl bg-white dark:bg-black/100 dark:backdrop-blur-sm
        shadow-lg dark:shadow-red-500/5 hover:scale-105 dark:hover:shadow-red-500/50
        border dark:border-white/10 dark:hover:border-white/10
        ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}
        transition-all duration-700 ease-out
      `}
    >
      <div className="w-12 h-12 rounded-xl bg-red-500 dark:bg-black
        flex items-center justify-center mb-4">
        <value.icon className="w-6 h-6 text-white dark:text-red-400" />
      </div>
      <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">{value.title}</h3>
      <p className="text-gray-600 dark:text-gray-400">{value.description}</p>
    </div>
  );
};

const ValuesSection = ({ values }) => {
  return (
    <section className="py-16 lg:py-24 bg-gray-50 dark:bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-transparent 
            dark:bg-clip-text dark:bg-gradient-to-r dark:from-white dark:to-gray-300 mb-4">
            Our Values
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-400">
            The principles that guide our mission and development
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {values.map((value, index) => (
            <ValueCard key={index} value={value} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ValuesSection;