import React from 'react';
import { useNavigate } from 'react-router-dom'; // Add this import
import { ArrowRight } from 'lucide-react';


const CTASection = () => {
  const navigate = useNavigate(); // Add this hook

  const handleClick = () => {
    navigate('/waitlist'); // This will navigate to the waitlist page
  };

  return (
    <section className="py-16 lg:py-24 bg-white dark:bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-red-600 to-red-700 
          dark:from-red-600 dark:to-red-500 dark:border dark:border-red-500/10">
          {/* Grid overlay */}
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:linear-gradient(0deg,transparent,black)]
            dark:bg-grid-white/[0.15]" />
          
          {/* Content */}
          <div className="relative p-8 lg:p-12 text-center">
            <h2 className="text-3xl lg:text-4xl font-bold text-white dark:text-transparent 
              dark:bg-clip-text dark:bg-gradient-to-r dark:from-white dark:to-gray-100 mb-4 pb-1">
              Ready to Start Your Fitness Journey?
            </h2>
            
            <p className="text-xl text-red-50 dark:text-gray-200 mb-8">
              Join a growing community
            </p>
            
            <button 
              onClick={handleClick}
              className="inline-flex items-center px-8 py-3 rounded-xl bg-white dark:bg-black/50 
                text-red-600 dark:text-red-400 font-semibold text-lg 
                shadow-lg shadow-red-500/30 dark:shadow-red-800 
                hover:shadow-xl hover:shadow-red-500/40 dark:hover:shadow-red-800/70 
                transition-all duration-200 group dark:backdrop-blur-sm
                dark:border dark:border-white/10 dark:hover:border-white/20
                hover:-translate-y-0.5"
            >
              Get Started Now
              <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform duration-200" />
            </button>
          </div>
          
          {/* Decorative shapes */}
          <div className="absolute -top-16 -left-16 w-64 h-64 bg-red-400/30 dark:bg-red-400/20 
            rounded-full blur-3xl" />
          <div className="absolute -bottom-16 -right-16 w-64 h-64 bg-red-400/30 dark:bg-red-400/20 
            rounded-full blur-3xl" />
        </div>
      </div>
    </section>
  );
};

export default CTASection;