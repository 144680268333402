const getEnvVar = (key, defaultValue = '') => {
  const value = process.env[key];
  return value !== undefined ? value : defaultValue;
};

export const config = {
  apiUrl: getEnvVar('REACT_APP_API_URL', 'http://localhost:5000'),
  apiKey: getEnvVar('REACT_APP_API_KEY', ''),
  appName: getEnvVar('REACT_APP_NAME', 'Fitverse'),
  version: getEnvVar('REACT_APP_VERSION', '1.0.0'),
  debug: getEnvVar('REACT_APP_DEBUG', 'false') === 'true',
  logLevel: getEnvVar('REACT_APP_LOG_LEVEL', 'error'),
  environment: getEnvVar('NODE_ENV', 'production')
};

// You can either use default export
export default config;
