import React from 'react';
import { ChevronRight, Brain, ActivitySquare, Target, History } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';


const ValueCard = ({ icon: Icon, title, description, delay = 0 }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`p-8 rounded-xl bg-white dark:bg-black/50 shadow-lg dark:shadow-red-500/5 
      transform transition-all duration-500 hover:scale-105 
      dark:backdrop-blur-sm dark:border dark:border-white/10 dark:hover:border-white/10
      dark:hover:shadow-red-500/50 ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      style={{ transitionDelay: `${delay}ms` }}
    >
            {/* this is part of the bottom icons */}

      {/* dark:from-red-500/20 dark:to-transparent  */}
      <div className="text-red-500 dark:text-red-400 mb-4 
        dark:bg-gradient-to-br 
        dark:p-3 dark:rounded-lg dark:inline-block">
        <Icon size={32} />
      </div>
      <h3 className="text-xl font-bold mb-2 text-gray-900 dark:text-white">{title}</h3>
      <p className="text-gray-600 dark:text-gray-400">{description}</p>
    </div>
  );
};

const StatsSection = () => {
  const navigate = useNavigate();
  const values = [
    {
      icon: Brain,
      title: "Smart Analysis",
      description: "Get personalized workout recommendations powered by AI that analyze your performance data.",
      delay: 0,
    },
    {
      icon: ActivitySquare,
      title: "Performance Tracking",
      description: "Monitor essential metrics and receive insights on areas that need improvement.",
      delay: 100,
    },
    {
      icon: Target,
      title: "Goal Setting",
      description: "Define achievable goals based on your data, and receive milestones that adapt to your progress.",
      delay: 200,
    },
    {
      icon: History,
      title: "Progress Overview",
      description: "Review your fitness journey over time and visualize your achievements.",
      delay: 300,
    },
  ];

  return (
    <section className="py-20 px-4 bg-gray-50 dark:bg-black relative">
      {/* Dark mode gradient background */}
      <div className="absolute inset-0 dark:bg-gradient-to-b dark:from-red-500/10 dark:via-transparent dark:to-transparent" />

      <div className="max-w-7xl mx-auto relative">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900">
            <span className="dark:text-transparent dark:bg-clip-text dark:bg-gradient-to-r dark:from-white dark:to-gray-300">
              Data-Driven Progress
            </span>
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            Turn your fitness data into actionable insights with our advanced tracking and analysis system.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {values.map((value, index) => (
            <ValueCard key={index} {...value} />
          ))}
        </div>

        <div className="mt-16 text-center">
          <button
            onClick={() => navigate('/features')}
            className="bg-gradient-to-r from-red-500 to-red-600 dark:from-red-600 dark:to-red-500 
              text-white px-8 py-3 rounded-lg font-semibold hover:opacity-90
              transition-all duration-300 inline-flex items-center group
              shadow-lg shadow-red-500/20 hover:shadow-red-500/30 
              dark:shadow-red-500/20 hover:-translate-y-0.5"
          >
            Explore All Features
            <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default StatsSection;