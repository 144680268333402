import React from 'react';

const ContactHero = () => {
  return (
    <section className="relative bg-white dark:bg-black overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-16 sm:py-20">
        <div className="text-center max-w-3xl mx-auto">
          <h1 className="text-4xl lg:text-5xl font-bold text-gray-900 dark:text-white mb-6 
            bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-700 
            dark:from-white dark:to-gray-300">
            Get in Touch
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Have questions? We'd love to hear from you. Send us a message and we'll respond as soon as possible.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactHero;