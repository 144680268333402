export const ANALYTICS_CONFIG = {
  // Google Analytics ID
  GA_MEASUREMENT_ID: process.env.REACT_APP_GA_ID,

  // Default event parameters
  DEFAULT_EVENT_PARAMS: {
    app_name: 'FitVerse',
    app_version: '1.0.0',
    platform: 'web'
  },

  // Event names
  EVENTS: {
    WAITLIST_SIGNUP: 'waitlist_signup',
    CONTACT_FORM_SUBMIT: 'contact_form_submit',
    FEATURE_VIEW: 'feature_view',
    COOKIE_CONSENT: 'cookie_consent_update'
  },

  // Custom dimensions
  CUSTOM_DIMENSIONS: {
    USER_CONSENT_LEVEL: 'user_consent_level',
    WAITLIST_SOURCE: 'waitlist_source',
    USER_TYPE: 'user_type'
  }
};