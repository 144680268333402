import React from 'react';
import { Star } from 'lucide-react';

const HeroSection = () => {
 return (
   <section className="relative bg-white dark:bg-black overflow-hidden pt-32 pb-20">
     {/* Gradient overlay for dark mode */}
     <div className="absolute inset-0 dark:bg-gradient-to-b dark:from-red-900/5 
       dark:via-transparent dark:to-transparent" />

     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
       <div className="text-center max-w-3xl mx-auto relative z-10">
         {/* Badge */}
         <div className="inline-flex items-center gap-2 bg-[#ef4444]/10 dark:bg-red-500/20 
           text-[#ef4444] dark:text-red-400 px-4 py-2 rounded-full text-sm font-medium mb-8
           dark:backdrop-blur-sm dark:border dark:border-red-500/20">
           <Star size={16} />
           <span>Our Vision for Fitness</span>
         </div>

         <h1 className="text-4xl lg:text-5xl font-bold text-gray-900 dark:text-white mb-6
           bg-clip-text">
           Transforming Fitness Through Technology
         </h1>
         
         <p className="text-xl text-gray-600 dark:text-gray-400 mb-8">
           We're on a mission to make personalized fitness guidance accessible to everyone through AI-powered solutions.
         </p>

         {/* Stars section if needed */}
         {/* <div className="flex justify-center space-x-2">
           {[...Array(5)].map((_, i) => (
             <Star key={i} className="w-6 h-6 text-yellow-400 dark:text-yellow-500/90" />
           ))}
         </div> */}

         {/* Optional background effect */}
         <div className="absolute -inset-10 bg-gradient-to-r from-[#ef4444]/10 
           dark:from-red-500/10 to-transparent blur-3xl -z-10 opacity-0 
           dark:opacity-100" />
       </div>
     </div>
   </section>
 );
};

export default HeroSection;