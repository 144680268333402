import React, { useEffect, useRef, useState, memo } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  Brain,
  BarChart2,
  Timer,
  ChevronRight,
  Play
} from 'lucide-react';

// Import videos for desktop (1080p)
import AiFeatureVideo1080 from '../../assets/videos/1080/AiFeature_1080p_60fps.mp4';
import TimerFeatureVideo1080 from '../../assets/videos/1080/TimerFeature_1080p_60fps.mp4';
import GraphicsFeatureVideo1080 from '../../assets/videos/1080/GraphicsFeature_1080p_60fps.mp4';

// Import videos for mobile (720p)
import AiFeatureVideo720 from '../../assets/videos/720/AiFeature720_30fps.mp4';
import TimerFeatureVideo720 from '../../assets/videos/720/TimerFeature720_30fps.mp4';
import GraphicsFeatureVideo720 from '../../assets/videos/720/GraphicsFeature720_30fps.mp4';

import { IPhoneFrame, VideoPlayer, VideoFallback } from '../common/PhoneDisplay';

VideoPlayer.displayName = 'VideoPlayer';
IPhoneFrame.displayName = 'IPhoneFrame';
VideoFallback.displayName = 'VideoFallback';

// Hook pentru detectarea tipului de dispozitiv
const useDeviceDetect = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkDevice = () => {
      // Verifică dacă este dispozitiv mobil bazat pe lățimea ecranului
      const isMobileWidth = window.matchMedia('(max-width: 1024px)').matches;

      // Verifică și User Agent pentru o detecție mai precisă
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

      // Verifică și caracteristicile touch
      const isTouchDevice = ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0);

      setIsMobile(isMobileWidth || isMobileDevice || isTouchDevice);
    };

    checkDevice();
    window.addEventListener('resize', checkDevice);
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  return isMobile;
};

const FeatureCard = memo(({ feature, isReversed }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false
  });

  const [videoError, setVideoError] = useState(false);
  const isMobile = useDeviceDetect();

  // Alege sursa video corectă bazată pe dispozitiv
  const videoSrc = isMobile ? feature.mediaUrl720 : feature.mediaUrl1080;

  const renderMedia = () => {
    switch (feature.mediaType) {
      case 'mobileVideo':
        return (
          <div className="relative w-full flex justify-center items-center">
            {/* Video Version Indicator - doar pentru dezvoltare
            <div className="absolute top-0 right-0 z-50 bg-black/80 text-white px-2 py-1 text-sm rounded-bl-lg">
              {isMobile ? '720p/30fps' : '1080p/60fps'}
            </div> */}

            <IPhoneFrame>
              {!videoError ? (
                <VideoPlayer
                  src={videoSrc}
                  onError={() => setVideoError(true)}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              ) : (
                <VideoFallback icon={feature.icon} />
              )}
            </IPhoneFrame>
          </div>
        );
      default:
        return (
          <div className="relative w-full flex justify-center items-center">
            <IPhoneFrame>
              <VideoFallback icon={feature.icon} />
            </IPhoneFrame>
          </div>
        );
    }
  };

  return (
    <div
      ref={ref}
      className={`
        flex flex-col lg:flex-row items-center gap-16 lg:gap-24 py-16 lg:py-32
        ${isReversed ? 'lg:flex-row-reverse' : ''}
        ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}
        transition-all duration-700 ease-out
      `}
    >
      <div className="flex-1 space-y-8 max-w-xl">
        <div className="inline-flex items-center justify-center w-16 h-16 rounded-2xl 
          bg-gradient-to-br from-red-500 to-red-600 dark:from-red-600 dark:to-red-500 
          shadow-lg shadow-red-500/20 dark:shadow-red-500/10">
          <feature.icon className="w-8 h-8 text-white" />
        </div>

        <div className="space-y-4">
          <h3 className="text-3xl font-bold text-gray-900 dark:text-white">
            {feature.title}
          </h3>
          <p className="text-lg text-gray-600 dark:text-gray-400 leading-relaxed">
            {feature.description}
          </p>
        </div>

        <div className="space-y-4">
          {feature.bulletPoints.map((point, index) => (
            <div key={index} className="flex items-start space-x-3">
              <div className="mt-2">
                <ChevronRight className="w-5 h-5 text-red-500 dark:text-red-400" />
              </div>
              <span className="text-gray-600 dark:text-gray-400 text-lg">{point}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="flex-1 w-full flex justify-center">
        {renderMedia()}
      </div>
    </div>
  );
});

FeatureCard.displayName = 'FeatureCard';

const features = [
  {
    icon: Brain,
    title: "AI-Powered Workout Planning",
    description: "Get personalized workout recommendations that adapt to your progress and preferences, all powered by advanced AI.",
    mediaType: 'mobileVideo',
    mediaUrl1080: AiFeatureVideo1080,
    mediaUrl720: AiFeatureVideo720,
    bulletPoints: [
      "Personalized workout suggestions based on your fitness level",
      "Smart exercise progression",
      "Real-time plan adjustments",
      "Recovery optimization"
    ]
  },
  {
    icon: BarChart2,
    title: "Track Your Progress",
    description: "Visualize your fitness journey with comprehensive analytics and insights.",
    mediaType: 'mobileVideo',
    mediaUrl1080: GraphicsFeatureVideo1080,
    mediaUrl720: GraphicsFeatureVideo720,
    bulletPoints: [
      "Detailed workout history",
      "Performance analytics",
      "Progress visualization"
    ]
  },
  {
    icon: Timer,
    title: "Smart Workout Timer",
    description: "Stay focused during your workouts with an intelligent timer that guides your training.",
    mediaType: 'mobileVideo',
    mediaUrl1080: TimerFeatureVideo1080,
    mediaUrl720: TimerFeatureVideo720,
    bulletPoints: [
      "Automatic rest periods",
      "Exercise timing",
      "Circuit training mode",
      "Custom interval setup"
    ]
  }
];

const FeatureShowcase = () => {
  return (
    <section className="py-16 lg:py-32 bg-white dark:bg-black overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto mb-16 lg:mb-24 space-y-6">
          <h2 className="text-4xl lg:text-5xl font-bold text-gray-900 dark:text-transparent 
            dark:bg-clip-text dark:bg-gradient-to-r dark:from-white dark:to-gray-300
            py-2">
            Transform Your Fitness Journey
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-400">
            Experience a smarter way to achieve your fitness goals with our AI-powered features.
          </p>
        </div>

        <div className="space-y-24 lg:space-y-32">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              feature={feature}
              isReversed={index % 2 !== 0}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default memo(FeatureShowcase);