// components/common/PhoneDisplay.jsx
import React, { useRef, useState, memo, useEffect, forwardRef } from 'react';

import { useInView } from 'react-intersection-observer';
import { Play } from 'lucide-react';

export const ImageDisplay = memo(({ src, alt, className }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div ref={ref} className="relative w-full h-full">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <div className="animate-pulse text-gray-400">Loading...</div>
        </div>
      )}

      <img
        src={inView ? src : ''}
        alt={alt}
        className={`
            ${className}
            ${isLoading ? 'opacity-0' : 'opacity-100'}
            transition-all duration-500
            object-cover w-full h-full
          `}
        onLoad={() => setIsLoading(false)}
        loading="lazy"
      />
    </div>
  );
});

export const VideoPlayer = memo(forwardRef(({ src, onError, className }, ref) => {
  const internalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false
  });

  // Combinăm ref-ul intern cu cel extern
  const videoRef = ref || internalRef;

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const playVideo = async () => {
      try {
        if (inView) {
          await video.play();
          setIsPlaying(true);
        } else {
          video.pause();
          setIsPlaying(false);
        }
      } catch (error) {
        console.warn('Video playback error:', error);
        setIsPlaying(false);
      }
    };

    playVideo();

    return () => {
      if (video) {
        video.pause();
        setIsPlaying(false);
      }
    };
  }, [inView]);

  const handlePlayClick = async () => {
    if (!videoRef.current) return;

    try {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        await videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } catch (error) {
      console.warn('Manual play error:', error);
    }
  };

  return (
    <div ref={inViewRef} className="relative w-full h-full overflow-hidden">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <div className="animate-pulse text-gray-400">Loading...</div>
        </div>
      )}

      <video
        ref={videoRef}
        className={`
          ${className}
          ${isLoading ? 'opacity-0' : 'opacity-100'}
          transition-all duration-500
          w-full h-full object-cover
        `}
        muted
        playsInline
        loop
        onLoadedData={() => setIsLoading(false)}
        onError={onError}
      >
        <source src={src} type="video/mp4" />
      </video>

      {(!isPlaying || !inView) && (
        <button
          onClick={handlePlayClick}
          className="absolute inset-0 flex items-center justify-center bg-black/20 transition-all duration-500"
          aria-label="Play video"
        >
          <Play className="w-12 h-12 text-white transition-transform duration-500" />
        </button>
      )}
    </div>
  );
}));

export const IPhoneFrame = memo(({ children, hasDynamicIsland = false }) => {
  return (
    <div className="relative mx-auto w-[280px] group cursor-pointer">
      {/* iPhone frame */}
      <div className="
          relative bg-black rounded-[50px] p-2 shadow-xl z-20
          transition-all duration-500 ease-out
          hover:shadow-red-500/50
          dark:shadow-red-500/10 dark:border dark:border-white/50
          dark:hover:shadow-red-500/50
        ">
        <div className="
            relative bg-white dark:bg-gray-800 rounded-[40px] overflow-hidden aspect-[9/19.5]
            transition-all duration-500
          ">
          {/* Dynamic Island */}
          {hasDynamicIsland ? (
            <div className="
                absolute top-[12px] left-1/2 -translate-x-1/2 w-[80px] h-[25px] 
                bg-black dark:bg-black rounded-full z-50
                transition-transform duration-500
              " />
          ) : null}

          {children}
        </div>
      </div>

      {/* Buttons */}
      <div className="
          absolute -right-[2px] top-[120px] w-[2px] h-10 
          bg-neutral-800 dark:bg-white/20 rounded-l
          transition-all duration-500 z-30
        " />
      <div className="
          absolute -left-[2px] top-[100px] w-[2px] h-12 
          bg-neutral-800 dark:bg-white/20 rounded-r
          transition-all duration-500 z-30
        " />
      <div className="
          absolute -left-[2px] top-[180px] w-[2px] h-12 
          bg-neutral-800 dark:bg-white/20 rounded-r
          transition-all duration-500 z-30
        " />
    </div>
  );
});

export const VideoFallback = memo(({ icon: Icon }) => (
  <div className="absolute inset-0 flex flex-col items-center justify-center bg-gradient-to-br from-gray-50 to-gray-100">
    <Icon className="w-12 h-12 text-gray-400 mb-4" />
    <p className="text-sm text-gray-500">Video preview coming soon</p>
  </div>
));

// Add display names
VideoPlayer.displayName = 'VideoPlayer';
IPhoneFrame.displayName = 'IPhoneFrame';
VideoFallback.displayName = 'VideoFallback';