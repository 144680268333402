import React, { useState } from 'react';
import { ArrowLeft, Loader2, XCircle, CheckCircle2, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import { apiClient } from '../../utils/api';
import WaitlistSuccess from './WaitlistSuccess';

const WaitlistForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    notifications: true
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (config.debug) {
      console.log('=== Form Submission Started ===');
      console.log('Environment:', config.environment);
    }

    setIsSubmitting(true);
    setError('');

    try {
      const response = await apiClient.post('/api/waitlist', formData);
      
      if (config.debug) {
        console.log('Submission successful:', response);
      }
      
      setIsSuccess(true); // Show success message instead of navigating
      
    } catch (err) {
      if (config.debug) {
        console.error('Submission failed:', err);
      }

      if (err.status === 401) {
        setError('Authentication error. Please contact support.');
      } else if (err.status === 400) {
        setError(err.message);
      } else if (err.message.includes('Unable to connect')) {
        setError('Unable to connect to our servers. Please try again later.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
      
      if (config.debug) {
        console.log('=== Form Submission Completed ===');
      }
    }
  };

  // If submission was successful, show success message
  if (isSuccess) {
    return (
      <WaitlistSuccess />
    );
  }

  // Otherwise show the form
  return (
    <div className="min-h-screen bg-white dark:bg-black flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md space-y-8">
        {/* Your existing form JSX */}
        <div>
          <Link
            to="/"
            className="inline-flex items-center text-sm text-gray-500 dark:text-gray-400 
              hover:text-gray-700 dark:hover:text-gray-200 transition-colors"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Home
          </Link>

          <h2 className="mt-6 text-3xl font-bold text-gray-900 dark:text-white">Join the Waitlist</h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Sign up to get early access to FITVERSE and be the first to experience the future of fitness tracking.
          </p>
        </div>

        {/* Error Message */}
        {error && (
          <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 
            text-red-800 dark:text-red-200 rounded-lg p-4 flex items-start mt-4">
            <XCircle className="w-5 h-5 text-red-400 dark:text-red-300 mr-2 flex-shrink-0 mt-0.5" />
            <span className="text-sm">{error}</span>
          </div>
        )}

        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                Name
              </label>
              <input
                id="name"
                type="text"
                required
                className="mt-1 block w-full rounded-lg border border-gray-300 dark:border-gray-700 
                  px-3 py-2 shadow-sm bg-white dark:bg-black dark:text-white
                  focus:border-[#ef4444] focus:ring-[#ef4444] dark:focus:border-red-800 
                  dark:focus:ring-red-800 focus:ring-opacity-50 focus:outline-none
                  dark:placeholder-gray-500"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                Email address
              </label>
              <input
                id="email"
                type="email"
                required
                className="mt-1 block w-full rounded-lg border border-gray-300 dark:border-gray-700 
                  px-3 py-2 shadow-sm bg-white dark:bg-black dark:text-white
                  focus:border-[#ef4444] focus:ring-[#ef4444] dark:focus:border-red-800 
                  dark:focus:ring-red-800 focus:ring-opacity-50 focus:outline-none
                  dark:placeholder-gray-500"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
            </div>

            <div className="flex items-center">
              <input
                id="notifications"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 dark:border-gray-700 
                  text-[#ef4444] dark:text-red-800 focus:ring-[#ef4444] dark:focus:ring-red-800"
                checked={formData.notifications}
                onChange={(e) => setFormData({ ...formData, notifications: e.target.checked })}
              />
              <label htmlFor="notifications" className="ml-2 block text-sm text-gray-600 dark:text-gray-400">
                Keep me updated about launch dates and early access opportunities
              </label>
            </div>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full flex justify-center items-center py-3 px-4 border border-transparent 
            rounded-lg shadow-sm text-sm font-medium text-white 
            bg-[#ef4444] dark:bg-gradient-to-r dark:from-red-600 dark:to-red-500
            hover:bg-[#ef4444]/90 dark:hover:shadow-lg dark:hover:shadow-red-900/30
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ef4444] 
            dark:focus:ring-red-800 transition-all duration-200 
            disabled:opacity-50 dark:disabled:opacity-40
            hover:-translate-y-0.5 dark:border-red-800/20 dark:hover:border-red-700/30
            dark:backdrop-blur-sm"
          >
            {isSubmitting ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Submitting...
              </>
            ) : (
              'Join Waitlist'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default WaitlistForm;