import React from 'react';
import { Users, Award, Target, Sparkles, LineChart, CircuitBoard } from 'lucide-react';
import { useInView } from 'react-intersection-observer';

const FeatureCard = ({ icon: Icon, title, description, delay }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`p-8 rounded-xl bg-white dark:bg-black/100 dark:backdrop-blur-sm 
      border dark:border-white/10 dark:hover:border-white/10 shadow-lg 
      dark:shadow-red-500/5 transform transition-all duration-500 hover:scale-105 
      dark:hover:shadow-red-500/50 ${
        inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
      style={{ transitionDelay: `${delay}ms` }}
    >
      {/* this is part of the bottom icons */}
      {/* dark:bg-gradient-to-br dark:from-red-600/20  */} /
      <div className="text-red-500 dark:text-red-400 mb-4 
      dark:to-red-900 p-3 rounded-lg inline-block">
        <Icon size={32} />
      </div>
      <h3 className="text-xl font-bold mb-2 text-gray-900 dark:text-white">{title}</h3>
      <p className="text-gray-600 dark:text-gray-400">{description}</p>
    </div>
  );
};

const FeaturesSection = () => {
  const features = [
    {
      icon: Sparkles,
      title: "AI-Driven Insights",
      description: "Receive detailed analyses and personalized recommendations to maximize the effectiveness of your workouts."
    },
    {
      icon: LineChart,
      title: "Progress Tracking",
      description: "Track your progress in real-time and adjust your goals based on your achievements."
    },
    {
      icon: Target,
      title: "Personal Goal Setting",
      description: "Set clear goals and let the app guide you towards achieving them successfully."
    },
    {
      icon: Users,
      title: "Active Community",
      description: "Connect with fellow fitness enthusiasts, share achievements, and motivate each other."
    },
    {
      icon: Award,
      title: "Gamification",
      description: "Earn badges and compete in challenges to turn your fitness routine into an engaging experience."
    },
    {
      icon: CircuitBoard,
      title: "AI Optimization",
      description: "Leverage AI to receive real-time workout plans and adjustments tailored to your needs."
    },
  ];

  return (
    <section className="py-20 px-4 bg-gray-50 dark:bg-black">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900 
            dark:text-transparent dark:bg-clip-text dark:bg-gradient-to-r dark:from-white dark:to-gray-300">
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            FITVERSE combines cutting-edge technology with proven training methods to 
            deliver the ultimate fitness experience.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 relative">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              {...feature}
              delay={index * 100}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
