import React from 'react';
import { Link } from 'react-router-dom';
import { Home, RefreshCcw } from 'lucide-react';

const NotFound = () => {
  return (
    <div className="min-h-screen bg-white dark:bg-black flex flex-col items-center justify-center p-4">
      {/* Background decorative elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-1/4 -right-1/4 w-1/2 h-1/2 bg-gradient-to-br 
          from-red-100 to-red-50 dark:from-red-900/20 dark:to-red-800/10 
          rounded-full blur-3xl opacity-50 dark:opacity-30" />
        <div className="absolute -bottom-1/4 -left-1/4 w-1/2 h-1/2 bg-gradient-to-tr 
          from-gray-100 to-gray-50 dark:from-gray-900/30 dark:to-gray-800/10 
          rounded-full blur-3xl opacity-50 dark:opacity-30" />
      </div>

      <div className="relative max-w-2xl mx-auto text-center space-y-10">
        {/* 404 Large Text */}
        <div className="space-y-2">
          <h1 className="text-8xl sm:text-9xl font-bold bg-clip-text text-transparent 
            bg-gradient-to-r from-gray-900 to-gray-600 
            dark:from-white dark:to-gray-500">
            404
          </h1>
          <p className="text-2xl sm:text-3xl font-semibold text-gray-900 dark:text-white">
            Page Not Found
          </p>
        </div>

        {/* Description */}
        <div className="space-y-6">
          <p className="text-gray-600 dark:text-gray-300 max-w-md mx-auto">
            The page you're looking for doesn't exist or has been moved. 
            Please check the URL or return to the homepage.
          </p>

          {/* Action Buttons */}
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <Link
              to="/"
              className="inline-flex items-center px-6 py-3 rounded-xl
                bg-gradient-to-r from-red-600 to-red-500 
                dark:from-red-500 dark:to-red-600
                text-white font-medium
                shadow-lg shadow-red-500/20 dark:shadow-red-800/30
                hover:shadow-xl hover:shadow-red-500/30 dark:hover:shadow-red-800/40
                hover:-translate-y-0.5 active:translate-y-0
                transition-all duration-200"
            >
              <Home className="w-5 h-5 mr-2" />
              Back to Home
            </Link>

            <button
              onClick={() => window.location.reload()}
              className="inline-flex items-center px-6 py-3 rounded-xl
                bg-white dark:bg-gray-900
                text-gray-900 dark:text-white font-medium
                border border-gray-200 dark:border-gray-700
                shadow-lg shadow-gray-200/20 dark:shadow-gray-900/30
                hover:shadow-xl hover:shadow-gray-200/30 dark:hover:shadow-gray-900/40
                hover:-translate-y-0.5 active:translate-y-0
                transition-all duration-200"
            >
              <RefreshCcw className="w-5 h-5 mr-2" />
              Reload Page
            </button>
          </div>
        </div>

        {/* Additional Help */}
        <div className="bg-gray-50 dark:bg-gray-900 p-6 rounded-2xl 
          border border-gray-100 dark:border-gray-800">
          <p className="text-sm text-gray-600 dark:text-gray-400">
            If you believe this is a mistake or need assistance, please{' '}
            <Link 
              to="/contact-us" 
              className="text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300 
                font-medium underline decoration-2 underline-offset-2"
            >
              contact our support team
            </Link>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;