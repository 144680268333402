import React, { useState } from 'react';
import { Mail, Instagram, Twitter, Linkedin } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import logoTransparent from '../../assets/logo/logo_transparent.webp';
import CookiePreferences from '../common/CookiePreferences';

const Footer = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);

  const handleCookiePreferences = () => {
    setIsPreferencesOpen(true);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <footer className="bg-gray-900 dark:bg-black text-white py-16 relative">
        {/* Gradient overlay for dark mode */}
        <div className="absolute inset-0 dark:bg-gradient-to-t dark:from-red-500/5 dark:via-transparent dark:to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Brand Section */}
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <img
                  src={logoTransparent}
                  alt="FITVERSE Logo"
                  className="w-8 h-8 rounded-lg dark:brightness-110"
                />
                <span className="text-xl font-bold dark:bg-clip-text dark:text-transparent 
                  dark:bg-gradient-to-r dark:from-white dark:to-gray-300">FITVERSE</span>
              </div>
              <p className="text-gray-400 dark:text-gray-300">
                Transform your fitness journey with the most comprehensive workout tracking platform.
              </p>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="text-lg font-semibold mb-4 dark:text-transparent dark:bg-clip-text 
                dark:bg-gradient-to-r dark:from-white dark:to-gray-300">Quick Links</h3>
              <ul className="space-y-2">
                {['Home', 'Features', 'About Us'].map((item) => (
                  <li key={item}>
                    <button 
                      onClick={() => handleNavigation(item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`)}
                      className="text-gray-400 dark:text-gray-300 hover:text-white dark:hover:text-white 
                        transition-colors hover:-translate-y-0.5 transform duration-300"
                    >
                      {item}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            {/* Resources */}
            <div>
              <h3 className="text-lg font-semibold mb-4 dark:text-transparent dark:bg-clip-text 
                dark:bg-gradient-to-r dark:from-white dark:to-gray-300">Resources</h3>
              <ul className="space-y-2">
                {['Contact Us', 'Privacy Policy', 'Cookie Policy', 'Cookie Settings'].map((item) => (
                  <li key={item}>
                    <button 
                      onClick={item === 'Cookie Settings' ? handleCookiePreferences : 
                        () => handleNavigation(`/${item.toLowerCase().replace(' ', '-')}`)}
                      className="text-gray-400 dark:text-gray-300 hover:text-white dark:hover:text-white 
                        transition-colors hover:-translate-y-0.5 transform duration-300"
                    >
                      {item}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            {/* Contact & Social */}
            <div>
              <h3 className="text-lg font-semibold mb-4 dark:text-transparent dark:bg-clip-text 
                dark:bg-gradient-to-r dark:from-white dark:to-gray-300">Connect With Us</h3>
              <div className="flex space-x-4 mb-4">
                {[
                  { icon: Instagram, href: 'https://www.instagram.com/fitversehub/', label: 'Instagram' },
                  { icon: Mail, href: 'mailto:info@fitversehub.com', label: 'Email' },
                  { icon: Twitter, href: 'https://x.com/FitVerseHub', label: 'X' },
                  { icon: Linkedin, href: 'https://www.linkedin.com/company/fitversehub', label: 'LinkedIn' }
                ].map((social) => (
                  <a
                    key={social.label}
                    href={social.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Follow us on ${social.label}`}
                    className="text-gray-400 dark:text-gray-300 hover:text-white dark:hover:text-white 
                      transition-all duration-300 hover:-translate-y-1 transform"
                  >
                    <social.icon className="w-6 h-6" />
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="border-t border-gray-800 dark:border-white/5 mt-8 mb-8">
            <div className="flex flex-col md:flex-row justify-between items-center mt-8">
              <p className="text-center text-gray-400 dark:text-gray-300">
                &copy; {currentYear} FITVERSE. All rights reserved.
              </p>
              <div className="flex space-x-6 mt-4 md:mt-0">
                {['Privacy Policy', 'Cookie Policy', 'Cookie Preferences'].map((item) => (
                  <button
                    key={item}
                    onClick={item === 'Cookie Preferences' ? handleCookiePreferences : 
                      () => handleNavigation(`/${item.toLowerCase().replace(' ', '-')}`)}
                    className="text-gray-400 dark:text-gray-300 hover:text-white dark:hover:text-white 
                      text-sm transition-colors hover:-translate-y-0.5 transform duration-300"
                  >
                    {item}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </footer>

      <CookiePreferences 
        isOpen={isPreferencesOpen}
        onClose={() => setIsPreferencesOpen(false)}
      />
    </>
    
  );
};

export default Footer;