import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border-b border-gray-200 dark:border-gray-800 hover:bg-gray-50/50 
          dark:hover:bg-gray-900/30 transition-colors rounded-lg">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full py-6 px-4 flex justify-between items-center text-left"
            >
                <span className="text-lg font-medium text-gray-900 dark:text-white">
                    {question}
                </span>
                {isOpen ? (
                    <ChevronUp className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                ) : (
                    <ChevronDown className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                )}
            </button>
            {isOpen && (
                <div className="pb-6 px-4">
                    <p className="text-gray-600 dark:text-gray-300">{answer}</p>
                </div>
            )}
        </div>
    );
};

const FAQSection = () => {
    const faqs = [
        {
            question: "When will the app be launched?",
            answer: "We're currently in the final stages of development and testing. Join our waitlist to be among the first to know about our launch date and get exclusive early access to the platform."
        },
        {
            question: "How does the waitlist work?",
            answer: "Enter your email to join the waitlist. Waitlist members will receive exclusive updates about our development progress, early access to the platform, and special founding member benefits when we launch."
        },
        {
            question: "How does the AI personalize my workout experience?",
            answer: "Our advanced AI analyzes multiple factors including your fitness goals, body measurements, progress photos, workout history, and performance data to create truly personalized training plans. It continually learns from your feedback and progress to adjust workouts in real-time, ensuring optimal progression and preventing plateaus."
        },
        {
            question: "Will there be a free tier available?",
            answer: "Yes! We're planning to offer both free and premium tiers. Waitlist members will get extended access to premium features during our initial launch period."
        },
        {
            question: "What makes your AI training suggestions different from standard workout apps?",
            answer: "Unlike static workout plans, our AI considers your recovery, adapts to your progress, and factors in your available equipment and time constraints. It can identify areas where you're making progress or struggling, and automatically adjusts your program. For example, if it notices your upper body is developing faster than your lower body, it will rebalance your routine accordingly."
        },
        {
            question: "How will the AI personalization work?",
            answer: "Our AI system will analyze your fitness goals, current level, progress photos, and workout history to suggest personalized routines and adjustments. It's designed to adapt as you progress, ensuring your training stays challenging and effective."
        },
        {
            question: "Can I use the app for any type of fitness goal?",
            answer: "Yes! Whether you're into bodybuilding, strength training, athletic performance, or general fitness, our platform is being designed to support various fitness goals and styles of training."
        },
        {
            question: "Will my data be secure?",
            answer: "Absolutely! We're building our platform with enterprise-grade security, including secure authentication through Clerk, encrypted data storage, and strict privacy controls for your personal information and progress photos."
        }
    ];

    return (
        <section className="py-16 lg:py-24 bg-gray-50 dark:bg-black">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto">
                    <h2 className="text-3xl font-bold text-center text-gray-900 dark:text-white
                      bg-gradient-to-r from-gray-900 to-gray-700 
                      dark:from-white dark:to-gray-300
                      bg-clip-text text-transparent mb-12">
                        FAQ
                    </h2>
                    <div className="space-y-2 bg-white dark:bg-black/50 rounded-2xl p-6 
                      shadow-xl dark:shadow-red-500/10 backdrop-blur-sm
                      border border-gray-100 dark:border-gray-800">
                        {faqs.map((faq, index) => (
                            <FAQItem key={index} {...faq} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQSection;