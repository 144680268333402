import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateConsent, trackEvent } from '../../utils/analytics';
import { ANALYTICS_CONFIG } from '../../config/analytics.config';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkConsentExpiry = () => {
      const consentData = localStorage.getItem('cookieConsent');
      if (consentData) {
        try {
          const { expires } = JSON.parse(consentData);
          if (new Date(expires) < new Date()) {
            localStorage.removeItem('cookieConsent');
            return null;
          }
          return consentData;
        } catch (e) {
          localStorage.removeItem('cookieConsent');
          return null;
        }
      }
      return null;
    };

    const consent = checkConsentExpiry();
    if (!consent) {
      setShowBanner(true);
      document.body.style.paddingBottom = '0';
    } else {
      document.body.style.paddingBottom = '0';
    }

    return () => {
      document.body.style.paddingBottom = '0';
    };
  }, []);

  const setCookieConsent = useCallback((status) => {
    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 12); // 12 luni

    const consentData = {
      status: status,
      expires: expiryDate.toISOString()
    };

    localStorage.setItem('cookieConsent', JSON.stringify(consentData));
  }, []);

  const handleAccept = useCallback(() => {
    setCookieConsent('accepted');
    setShowBanner(false);
    document.body.style.paddingBottom = '0';
    updateConsent(true);

    trackEvent(ANALYTICS_CONFIG.EVENTS.COOKIE_CONSENT, {
      [ANALYTICS_CONFIG.CUSTOM_DIMENSIONS.USER_CONSENT_LEVEL]: 'full',
      status: 'accepted',
      source: 'banner'
    });
  }, [setCookieConsent]);

  const handleDecline = useCallback(() => {
    setCookieConsent('declined');
    setShowBanner(false);
    document.body.style.paddingBottom = '0';
    updateConsent(false);

    trackEvent(ANALYTICS_CONFIG.EVENTS.COOKIE_CONSENT, {
      [ANALYTICS_CONFIG.CUSTOM_DIMENSIONS.USER_CONSENT_LEVEL]: 'none',
      status: 'declined',
      source: 'banner'
    });
  }, [setCookieConsent]);

  const handleLearnMore = useCallback(() => {
    navigate('/cookie-policy');
  }, [navigate]);

  const bannerText = useMemo(() => (
    <p className="text-gray-700 dark:text-gray-300">
      We use cookies to enhance your experience on FITVERSE and analyze
      our website traffic. By clicking "Accept", you consent to our use of cookies.
      <button
        onClick={handleLearnMore}
        className="text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300 
          underline ml-1 transition-colors"
      >
        Learn more
      </button>
    </p>
  ), [handleLearnMore]);

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-black 
    shadow-lg dark:shadow-2xl border-t border-gray-200 dark:border-gray-800 
    p-4 z-[60] backdrop-blur-sm bg-white/80 dark:bg-black/90">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <div className="text-sm mr-8 mb-4 sm:mb-0">
            {bannerText}
          </div>
          <div className="flex space-x-4">
            <button
              onClick={handleDecline}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 
                bg-gray-100 dark:bg-gray-800 
                hover:bg-gray-200 dark:hover:bg-gray-700 
                rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 
                focus:ring-gray-500 dark:focus:ring-gray-600
                transition-colors"
            >
              Decline
            </button>
            <button
              onClick={handleAccept}
              className="px-4 py-2 text-sm font-medium text-white 
                bg-red-600 dark:bg-red-500 
                hover:bg-red-700 dark:hover:bg-red-600 
                rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 
                focus:ring-red-500 dark:focus:ring-red-400
                transition-colors"
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;