import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { updateConsent, trackEvent } from '../../utils/analytics';
import { ANALYTICS_CONFIG } from '../../config/analytics.config';

const CookiePreferences = ({ isOpen, onClose }) => {
  const [preferences, setPreferences] = useState({
    analytics: false
  });

  useEffect(() => {
    if (isOpen) {
      const consent = localStorage.getItem('cookieConsent');
      setPreferences({
        analytics: consent === 'accepted'
      });
    }
  }, [isOpen]);

  const handleSave = () => {
    const newConsentStatus = preferences.analytics ? 'accepted' : 'declined';
    localStorage.setItem('cookieConsent', newConsentStatus);
    updateConsent(preferences.analytics);
    
    trackEvent(ANALYTICS_CONFIG.EVENTS.COOKIE_CONSENT, {
      [ANALYTICS_CONFIG.CUSTOM_DIMENSIONS.USER_CONSENT_LEVEL]: preferences.analytics ? 'full' : 'none',
      status: newConsentStatus,
      source: 'preferences_modal'
    });
    
    onClose();
    window.location.reload();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 dark:bg-black/70 z-[70] flex items-center justify-center p-3 sm:p-4">
      <div className="bg-white dark:bg-black/90 rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto 
        border border-gray-200 dark:border-gray-800">
        <div className="p-4 sm:p-6">
          {/* Header */}
          <div className="flex justify-between items-center mb-4 sm:mb-6 sticky top-0 bg-white dark:bg-black/90 pb-2">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 dark:text-white">
              Cookie Preferences
            </h2>
            <button
              onClick={onClose}
              className="p-1 text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300 
                transition-colors"
              aria-label="Close"
            >
              <X className="w-5 h-5 sm:w-6 sm:h-6" />
            </button>
          </div>

          <div className="space-y-6 sm:space-y-8">
            {/* Essential Cookies Section */}
            <div className="border-b border-gray-200 dark:border-gray-800 pb-4 sm:pb-6">
              <div className="flex items-start justify-between gap-4">
                <div className="flex-1">
                  <h3 className="font-semibold text-base sm:text-lg text-gray-900 dark:text-white">
                    Essential Cookies
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400 mt-1 text-sm sm:text-base">
                    These cookies are necessary for the website to function and cannot be switched off.
                    They are usually only set in response to actions made by you such as setting your privacy preferences,
                    logging in, or filling in forms.
                  </p>
                </div>
                <div className="bg-gray-100 dark:bg-gray-900 px-2 py-1 rounded text-xs sm:text-sm 
                  text-gray-700 dark:text-gray-300 whitespace-nowrap">
                  Always Active
                </div>
              </div>
            </div>

            {/* Analytics Cookies Section */}
            <div className="border-b border-gray-200 dark:border-gray-800 pb-4 sm:pb-6">
              <div className="flex items-start justify-between gap-4">
                <div className="flex-1">
                  <h3 className="font-semibold text-base sm:text-lg text-gray-900 dark:text-white">
                    Analytics Cookies
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400 mt-1 text-sm sm:text-base">
                    These cookies allow us to count visits and traffic sources so we can measure and improve 
                    the performance of our site. They help us understand which pages are the most popular and 
                    see how visitors move around the site.
                  </p>
                </div>
                <label className="relative inline-flex items-center cursor-pointer flex-shrink-0">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={preferences.analytics}
                    onChange={(e) => setPreferences(prev => ({
                      ...prev,
                      analytics: e.target.checked
                    }))}
                  />
                  <div className="w-11 h-6 bg-gray-200 dark:bg-gray-700                     
                    rounded-full peer 
                    peer-checked:after:translate-x-full 
                    peer-checked:after:border-white 
                    after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                    after:bg-white after:border-gray-300 dark:after:border-gray-600 
                    after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
                    peer-checked:bg-red-600 dark:peer-checked:bg-red-500"></div>
                </label>
              </div>
            </div>

            {/* More Information Section */}
            <div>
              <p className="text-gray-600 dark:text-gray-400 text-xs sm:text-sm">
                For more information about our cookie practices, please visit our{' '}
                <Link to="/cookie-policy" 
                  className="text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300 underline" 
                  onClick={onClose}>
                  Cookie Policy
                </Link>.
              </p>
            </div>
          </div>

          {/* Footer Buttons */}
          <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row justify-end gap-3 sm:gap-4">
            <button
              onClick={onClose}
              className="w-full sm:w-auto px-4 py-2.5 text-sm font-medium 
                text-gray-700 dark:text-gray-300 
                bg-gray-100 dark:bg-gray-800 
                hover:bg-gray-200 dark:hover:bg-gray-700 
                rounded-md transition-colors order-2 sm:order-1"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="w-full sm:w-auto px-4 py-2.5 text-sm font-medium text-white 
                bg-red-600 dark:bg-red-500 
                hover:bg-red-700 dark:hover:bg-red-600 
                rounded-md transition-colors order-1 sm:order-2"
            >
              Save Preferences
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePreferences;