import React from 'react';
import { CheckCircle2, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const WaitlistSuccess = () => {
 return (
   <div className="min-h-screen bg-white dark:bg-black flex flex-col items-center justify-center p-4">
     <div className="w-full max-w-md text-center space-y-6">
       <CheckCircle2 className="w-16 h-16 text-[#ef4444] dark:text-red-700 mx-auto" />
       
       <div className="space-y-2">
         <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
           You're on the list!
         </h1>
         <p className="text-gray-600 dark:text-gray-400">
           Thank you for joining the FITVERSE waitlist. We'll notify you when early access becomes available.
         </p>
       </div>

       <div className="bg-gray-50 dark:bg-gray-900/50 p-4 rounded-lg 
         border border-transparent dark:border-white/5 backdrop-blur-sm">
         <p className="text-sm text-gray-600 dark:text-gray-400">
           While you wait, follow us on social media for the latest updates and fitness tips.
         </p>
       </div>

       <Link
         to="/"
         className="inline-flex items-center text-[#ef4444] dark:text-red-600 
           hover:text-[#ef4444]/80 dark:hover:text-red-500 transition-colors
           hover:-translate-y-0.5 transform duration-200"
       >
         Return to Home
         <ArrowRight className="w-4 h-4 ml-2" />
       </Link>
     </div>

     {/* Optional: Subtle gradient effect for dark mode */}
     <div className="fixed inset-0 bg-gradient-to-b from-red-900/5 
       via-transparent to-transparent opacity-0 dark:opacity-100 
       pointer-events-none -z-10" />
   </div>
 );
};

export default WaitlistSuccess;