import React from 'react';
import { MapPin, Mail } from 'lucide-react';

const ContactCard = ({ icon: Icon, title, content }) => (
  <div className="flex flex-col items-center text-center p-8 rounded-2xl 
    bg-white dark:bg-black/50 border border-gray-200 dark:border-gray-800
     transition-all duration-300 backdrop-blur-sm">
    <div className="w-14 h-14 rounded-full bg-red-100 dark:bg-red-500/10 
      flex items-center justify-center mb-4 
      shadow-lg shadow-red-500/10 dark:shadow-red-500/20">
      <Icon className="w-7 h-7 text-red-500 dark:text-red-400" />
    </div>
    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
      {title}
    </h3>
    <p className="text-gray-600 dark:text-gray-400">
      {content}
    </p>
  </div>
);

const ContactInfo = () => {
  const contactDetails = [
    {
      icon: MapPin,
      title: "Location",
      content: "Brasov, Romania"
    },
    {
      icon: Mail,
      title: "Email",
      content: "info@fitversehub.com"
    }
  ];

  return (
    <section className="py-12 lg:py-20 bg-gray-50 dark:bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {contactDetails.map((detail, index) => (
            <ContactCard key={index} {...detail} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;