// src/pages/Home.jsx
import React from 'react';
import HeroSection from '../components/home/HeroSection';
import FeaturesSection from '../components/home/FeaturesSection';
import StatsSection from '../components/home/StatsSection';
import { usePageTime } from '../hooks/usePageTime';

const Home = () => {
  usePageTime();

  return (
    <main className="bg-gray-50 dark:bg-black">
      <HeroSection />
      <FeaturesSection />
      <StatsSection />
    </main>
  );
};

export default Home;