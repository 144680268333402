//can be used when having prices

// import React from 'react';

// const CTASection = () => {
//   return (
//     <section className="py-20 px-4 bg-gradient-to-br from-red-500 to-red-600">
//       <div className="max-w-7xl mx-auto text-center text-white">
//         <h2 className="text-3xl md:text-4xl font-bold mb-6">
//           Ready to Transform Your Fitness Journey?
//         </h2>
//         <p className="text-xl mb-8 opacity-90">
//           Join thousands of users who are already achieving their fitness goals with FITVERSE.
//         </p>
//         <div className="flex flex-col sm:flex-row gap-4 justify-center">
//           <button className="bg-white text-red-500 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
//             Start Free Trial
//           </button>
//           <button className="bg-transparent border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition-colors">
//             View Pricing
//           </button>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default CTASection;


import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const CTASection = () => {
  const navigate = useNavigate();

  return (
    <section className="py-24 bg-white dark:bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-red-600 to-red-700 
          dark:from-red-600 dark:to-red-500 dark:border dark:border-red-500/10">
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:linear-gradient(0deg,transparent,black)]
            dark:bg-grid-white/[0.15]" />
          
          <div className="relative max-w-2xl mx-auto text-center py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h2 className="text-3xl sm:text-4xl font-bold text-white dark:text-transparent 
              dark:bg-clip-text dark:bg-gradient-to-r dark:from-white dark:to-gray-100 mb-6">
              Ready to Transform Your Fitness Journey?
            </h2>
            
            <p className="text-lg sm:text-xl text-red-50 dark:text-gray-200 mb-10">
              Join our waitlist today and be among the first to experience the future of personalized fitness.
            </p>
            
            <button
              onClick={() => navigate('/waitlist')}
              className="inline-flex items-center px-8 py-4 rounded-full bg-white dark:bg-black/50 
                text-red-600 dark:text-red-400 font-semibold text-lg 
                shadow-lg shadow-red-500/30 dark:shadow-red-800 
                hover:shadow-xl hover:shadow-red-500/40 dark:hover:shadow-red-800/70 
                transition-all duration-200 group dark:backdrop-blur-sm
                dark:border dark:border-white/10 dark:hover:border-white/20
                hover:-translate-y-0.5"
            >
              Join Waitlist
              <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform duration-200" />
            </button>
          </div>
          
          {/* Decorative shapes */}
          <div className="absolute -top-16 -left-16 w-64 h-64 bg-red-400/30 dark:bg-red-400/20 
            rounded-full blur-3xl" />
          <div className="absolute -bottom-16 -right-16 w-64 h-64 bg-red-400/30 dark:bg-red-400/20 
            rounded-full blur-3xl" />
        </div>
      </div>
    </section>
  );
};

export default CTASection;