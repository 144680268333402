import React from 'react';
import FeaturesHero from '../components/features/FeaturesHero';
import FeatureShowcase from '../components/features/FeatureShowcase';
import CTASection from '../components/features/CTASection';
import { usePageTime } from '../hooks/usePageTime';

const Features = () => {
  usePageTime();

  return (
    <main className="bg-gray-50 dark:bg-black">
      <FeaturesHero />
      <FeatureShowcase />
      <CTASection />
    </main>
  );
};

export default Features;