import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Layouts
import MainLayout from './components/layout/MainLayout';

// Pages
import Home from './pages/Home';
import Features from './pages/Features';
import About from './pages/about/index';
import ContactPage from './pages/contact/index';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import NotFound from './pages/NotFound';

// Components
import WaitlistForm from './components/forms/WaitlistForm';
import CookieConsent from './components/common/CookieConsent';
import ScrollToTop from './components/common/ScrollToTop';

// Utils & Hooks
import { initGA } from './utils/analytics';
import { useAnalytics } from './hooks/useAnalytics';

// Future imports - commented out until implementation
// import Blog from './pages/Blog';
// import HelpCenter from './pages/HelpCenter';
// import Community from './pages/Community';
import Pricing from './pages/Pricing';

// Styles
import './index.css';

// Theme
import ThemeProvider from './components/theme/ThemeProvider';

function App() {
  // Initialize GA on mount
  useEffect(() => {
    initGA();
  }, []);

  return (
    <ThemeProvider>
      
      <Router>
        {/* Analytics Hook */}
        <AnalyticsWrapper />
        <ScrollToTop />

        <Routes>
          {/* Routes with MainLayout (includes Navbar & Footer) */}
          <Route element={<MainLayout />}>
            {/* Main Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/features" element={<Features />} />
            <Route path="/about-us" element={<About />} />

            {/* Waitlist Routes */}
            <Route path="/waitlist" element={<WaitlistForm />} />

            {/* Contact Routes */}
            <Route path="/contact-us" element={<ContactPage />} />

            {/* Legal & Privacy Routes */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />

            {/* Future Routes - commented out until implementation */}
            {/* <Route path="/blog" element={<Blog />} /> */}
            {/* <Route path="/help" element={<HelpCenter />} /> */}
            {/* <Route path="/community" element={<Community />} /> */}
            <Route path="/pricing" element={<Pricing />} />

            {/* 404 Route */}
            <Route path="*" element={<NotFound />} />

          </Route>
        </Routes>

        {/* Global Components */}
        <CookieConsent />
      </Router>
    </ThemeProvider>
  );
}

// Wrapper component for analytics
const AnalyticsWrapper = () => {
  useAnalytics(); // This will track page views
  return null;
};

export default App;