import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle, ArrowLeft } from 'lucide-react';
import { usePageTime } from '../../hooks/usePageTime';

const ContactSuccess = () => {
  usePageTime();

  return (
    <div className="min-h-screen bg-white dark:bg-black flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md">
        <div className="text-center">
          <CheckCircle className="w-16 h-16 mx-auto mb-6 text-[#ef4444] dark:text-red-500" />
          
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
            Message Sent Successfully!
          </h2>
          
          <p className="mt-4 text-sm text-gray-600 dark:text-gray-400">
            Thank you for reaching out. We've received your message and will get back to you as soon as possible.
          </p>

          <Link
            to="/"
            className="mt-8 inline-flex items-center justify-center w-full py-3 px-4 
              border border-transparent rounded-lg shadow-sm text-sm font-medium text-white 
              bg-[#ef4444] dark:bg-gradient-to-r dark:from-red-600 dark:to-red-500
              hover:bg-[#ef4444]/90 dark:hover:shadow-lg dark:hover:shadow-red-900/30
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ef4444] 
              dark:focus:ring-red-800 transition-all duration-200 
              hover:-translate-y-0.5 dark:border-red-800/20 dark:hover:border-red-700/30
              dark:backdrop-blur-sm"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContactSuccess;