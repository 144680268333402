import React from 'react';
import { ArrowRight, Sparkles } from 'lucide-react';
import { useNavigate } from 'react-router-dom';


const FeaturesHero = () => {
  const navigate = useNavigate();

  return (
    <section className="pt-32 pb-16 px-4 dark:bg-black">
      <div className="max-w-7xl mx-auto text-center">
        <div className="inline-flex items-center gap-2 bg-red-50 dark:bg-red-500/10 
          text-red-500 dark:text-red-400 px-4 py-2 rounded-full text-sm font-medium mb-8
          dark:backdrop-blur-sm dark:border dark:border-red-500/20">
          <Sparkles size={16} />
          <span>Early Access Coming Soon</span>
        </div>

        <h1 className="text-4xl md:text-5xl font-bold mb-6 dark:text-white">
          The Future of Fitness Tracking
          <span className="text-red-500 dark:text-transparent dark:bg-clip-text 
            dark:bg-gradient-to-r dark:from-red-600 dark:to-red-500"> is Here</span>
        </h1>
        <p className="text-xl text-gray-600 dark:text-gray-400 max-w-2xl mx-auto mb-8">
          Experience the next generation of workout tracking and analysis. 
          Powered by AI, designed for results, and built with your feedback.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <button 
            onClick={() => navigate('/waitlist')} 
            className="bg-red-500 dark:bg-gradient-to-r dark:from-red-600 dark:to-red-500 
              text-white px-8 py-3 rounded-lg font-semibold hover:bg-red-600 
              dark:hover:shadow-lg dark:hover:shadow-red-500/20 dark:hover:-translate-y-0.5
              transition-all duration-300 inline-flex items-center justify-center">
            Join Early Access
            <ArrowRight className="ml-2" />
          </button>
          {/* <button className="bg-gray-100 dark:bg-white/5 px-8 py-3 rounded-lg font-semibold 
            hover:bg-gray-200 dark:hover:bg-white/10 dark:text-white transition-colors">
            View Features
          </button> */}
        </div>
      </div>
    </section>
  );
};

export default FeaturesHero;