// hooks/usePageTime.js
import { useEffect } from 'react';
import { trackSEOMetrics } from '../utils/analytics';

export const usePageTime = () => {
  useEffect(() => {
    const startTime = new Date();

    return () => {
      const endTime = new Date();
      const timeSpent = (endTime - startTime) / 1000; // în secunde

      trackSEOMetrics({
        event_category: 'User Engagement',
        event_action: 'page_time',
        time_spent: timeSpent,
        page_name: document.title
      });
    };
  }, []);
};